<template>
    <div id="visit" v-if="!loading">
        <div class="common-header">
            <div class="left-wrap" @click="onBack()">
                <i class="el-icon-arrow-left visit-back"></i><span class="visit-title">{{researchName}}</span>
            </div>
            <div class="right-wrap" v-if="!isHistiry && !visitHistory">
                <el-button v-show="!dataList.length" class="visit-addOrRelease" size="mini" type="primary"
                           @click="visitAdd" :class="{'disabled':canDesignFrom}">添加访视
                </el-button>
                <el-button class="visit-addOrRelease" size="mini" type="primary" @click="setSendDialogFormVisible(true)"
                           v-if="dataList.length">发布
                </el-button>
                <span @click="onChangeViewToHistory">发布历史</span>
            </div>
            <div class="right-wrap" v-if="isHistiry">
                <el-button v-show="!dataList.length" class="visit-addOrRelease" size="mini" type="primary"
                           @click="visitAdd" :class="{'disabled':canDesignFrom}">添加访视
                </el-button>
                <el-button class="visit-addOrRelease" size="mini" type="primary" @click="$router.go(-1)">返回</el-button>
                <el-popconfirm title="该操作会覆盖当前表单设计草稿,是否继续执行？" v-if="isEdit!=0" @confirm="onEditVisit">
                    <el-link slot="reference" class="visit-addOrRelease" size="mini" type="primary">编辑</el-link>
                </el-popconfirm>
                <el-button class="visit-addOrRelease" size="mini" type="primary" @click="setSendDialogFormVisible(true)"
                           v-if="isEdit==0&& dataList.length">发布
                </el-button>
            </div>
        </div>
        <div class="common-btn" v-if="isHistiry && isEdit==1">
            <el-alert center :title="historyTitle" type="warning" show-icon :closable='false'></el-alert>
        </div>
        <!-- 发布弹框 -->
        <el-dialog :title="sendDialogTitle" :visible="sendDialogFormVisible" @close="setSendDialogFormVisible(false)">
            <el-form :model="sendForm" label-width="100px" :rules="rules" ref="sendForm">
                <el-form-item label="版本号" prop="versionNum">
                    <el-input clearable v-model="sendForm.versionNum" autocomplete="off" maxlength="255"></el-input>
                </el-form-item>
                <el-form-item label="版本日期" prop="versionDate">
                    <el-date-picker v-model="sendForm.versionDate" value-format="yyyy-MM-dd HH:mm:ss" type="date"
                                    placeholder="选择版本日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="发布到">
                    <el-radio-group v-model="sendForm.publishEnv">
                        <el-radio :label="1" v-if="!visitHistory">测试环境</el-radio>
                        <el-radio :label="2">生产环境</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input clearable type="textarea" placeholder="请输入备注" v-model="sendForm.remark" maxlength="500"
                              show-word-limit :autosize="{ minRows: 4, maxRows: 6}"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="setSendDialogFormVisible(false)">取 消</el-button>
                <el-button size="mini" type="primary" @click="onSubmitSend" :loading='sendBtnLoading'>确 定</el-button>
            </div>
        </el-dialog>
        <!-- 添加访视弹窗 -->
        <el-dialog :title="visitTitle" :visible.sync="dialogVisible" width="30%" custom-class="visit-dialog"
                   @open="submitButtonDisabled = false">
            <el-form size="mini" ref="visitForm" :model="visitForm" :rules="visitRules" label-width="80px">
                <el-form-item label="访视阶段" prop="stage">
                    <el-input placeholder="请输入访视阶段" clearable v-model="visitForm.stage"></el-input>
                </el-form-item>
                <el-form-item label="访视名称" prop="title">
                    <el-input placeholder="请输入访视名称" clearable v-model="visitForm.title"></el-input>
                </el-form-item>
                <el-form-item label="访视编号" prop="id">
                    <el-input :disabled="visitTitle==='编辑访视'" placeholder="请输入访视编号" clearable
                              v-model="visitForm.id"></el-input>
                </el-form-item>
                <el-form-item label="访视类型" prop="type">
                    <el-select :style="{'width':'100%'}" v-model="visitForm.type" placeholder="请选择研究类型">
                        <el-option label="计划" value="1"></el-option>
                        <el-option label="非计划" value="0"></el-option>
                        <el-option label="通用" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="可否重复" prop="selectionType">
                    <el-select :style="{'width':'100%'}" v-model="visitForm.selectionType"
                               :disabled="visitTitle==='编辑访视'" placeholder="请选择可否重复">
                        <el-option label="可重复" value="Multiple"></el-option>
                        <el-option label="不可重复" value="Single"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
      <el-button v-show="visitTitle==='新建访视'" size="mini" type="primary" @click="addVisitConfirm('visitForm')"
                 :disabled="submitButtonDisabled">确 定</el-button>
      <el-button v-show="visitTitle==='编辑访视'" size="mini" type="primary" @click="editVisitConfirm('visitForm')"
                 :disabled="submitButtonDisabled">保 存</el-button>
    </span>
        </el-dialog>
        <router-view></router-view>
    </div>
</template>

<script>

    import {
        getVisit,
        addVisit,
        editVisit,
        deleteVisit,
        addForm,
        editForm,
        deleteForm,
        moveForm,
        copyForm,
        sortForm,
        getLastVersionCode,
        publishVersion
    } from "@/api/visit";

    import draggable from "vuedraggable";
    import {
        Loading
    } from 'element-ui';
    import {
        SelectorDefine
    } from '../utils/SelectorDefine'
    import globalConst from '../utils/globalConst'
    import {
        localData
    } from "../utils/storage"
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex';

    let loadingInstance = null;
    export default {
        name: 'Visit',
        components: {
            draggable
        },
        computed: {
            ...mapState(['dataList', 'isEdit', 'sendDialogFormVisible', 'sendForm', 'isFineshed']),
            sendDialogTitle() {
                return this.researchName + '发布'
            },
            canDesignFrom() {
//                if (!globalConst.devEnv)
//                    return false;
//                else
//                    return this.releaseStatus == 1 || this.releaseStatus == 2 || this.releaseStatus == 3;
                    return false;
            },
            addFormParams() {
                return {
                    registryEntityCode: this.$route.query.code,
                    code: this.visitFormAdd.id,
                    parentCode: this.visitItem.code,
                    displayNameCn: this.visitFormAdd.name,
                    name: this.visitFormAdd.name,
                    selectionType: this.visitFormAdd.selectionType,
                    category: this.visitFormAdd.category,
                    repeatNum: this.visitFormAdd.repeatNum,
                    allowAdd: this.visitFormAdd.allowAdd,
                    isEpro: this.visitFormAdd.isEpro,
                    status: '0',
                    versionCode: this.versionCode,
                };
            },
            editFormParams() {
                return {
                    pkId: this.visitFormAdd.pkId,
                    registryEntityCode: this.$route.query.code,
                    code: this.visitFormAdd.id,
                    parentCode: this.visitFormAdd.parentCode,
                    displayNameCn: this.visitFormAdd.name,
                    name: this.visitFormAdd.name,
                    selectionType: this.visitFormAdd.selectionType,
                    category: this.visitFormAdd.category,
                    repeatNum: this.visitFormAdd.repeatNum,
                    allowAdd: this.visitFormAdd.allowAdd,
                    isEpro: this.visitFormAdd.isEpro,
                    status: '0',
                    versionCode: this.versionCode
                };
            },
        },
        watch: {
            '$route': {
                immediate: true,
                handler: function (n) {
                    if (localStorage.getItem('isSended') == 1) {
                        this.visitHistory = false
                        this.isHistiry = false
                    } else {
                        this.visitHistory = n.name === 'visitHistory'
                        this.isHistiry = this.$route.query.history || false
                    }

                }
            }
        },
        data() {
            return {
                visitHistory: false,
                isHistiry: '',
                historyTitle: '',
                formLabelWidth: '80px',
                // sendForm:{
                //     publishEnv:1,
                //     versionNum:'',
                //     versionDate:''
                // },
                selector: {},
                loading: true,
                researchName: this.$route.query.name,
                releaseStatus: this.$route.query.release,
                versionCode: this.$route.query.versionCode,
                visitTitle: '新建访视',
                dialogVisible: false,
                dialogVisibleAdd: false,
                visitForm: {
                    title: '',
                    id: '',
                    type: '0',
                    selectionType: 'Single',
                    stage: '',
                    data: []
                },
                visitRules: {
                    title: [{
                        required: true,
                        message: '请输入访视名称',
                        trigger: 'blur'
                    },
                        {
                            min: 1,
                            max: 255,
                            message: '长度在 1 到 255 个字符',
                            trigger: 'blur'
                        }
                    ],
                    id: [{
                        required: true,
                        message: '请输入访视编号',
                        trigger: 'blur'
                    },
                        {
                            pattern: /^[A-Za-z0-9]{1,255}$/,
                            message: '请输入 255 位以内数字或字母'
                        }
                    ],
                    type: [{
                        required: true,
                        message: '请选择访视类型'
                    }],
                    selectionType: [{
                        required: true,
                        message: '请选可否重复'
                    }],
                    stage: [
                        {min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur'}
                    ],
                },
                rules: {
                    versionNum: [{
                        required: true,
                        message: '请输入版本号',
                        trigger: 'blur'
                    },
                        {
                            min: 1,
                            max: 255,
                            message: '长度在 1 到 255 个字符',
                            trigger: 'blur'
                        }
                    ],
                    versionDate: [{
                        required: true,
                        message: '请选择版本日期'
                    }]
                },
                visitFormAdd: {
                    name: '',
                    id: '',
                    selectionType: 'Single',
                    category: '0',
                    repeatNum: 0,
                    allowAdd: true,
                    isEpro: '0',
                    parentCode: '',
                },
                visitRulesAdd: {
                    name: [{
                        required: true,
                        message: '请输入表单名称',
                        trigger: 'blur'
                    },
                        {
                            min: 1,
                            max: 255,
                            message: '长度在 1 到 255 个字符',
                            trigger: 'blur'
                        }
                    ],
                    id: [{
                        required: true,
                        message: '请输表单编号',
                        trigger: 'blur'
                    },
                        {
                            pattern: /^[A-Za-z0-9]{1,255}$/,
                            message: '请输入 255 位以内数字或字母'
                        }
                    ],
                    selectionType: [{
                        required: true,
                        message: '请选择可否重复'
                    }],
                },
                visitTitle: '添加表单',
                searchString: '',
                searchStringCopy: '',
                //防止按钮重复点击
                submitButtonDisabled: false,
                sendBtnLoading: false
            }
        },
        created() {
            loadingInstance = Loading.service({
                fullscreen: true,
                text: 'Loading',
            });
            //初始数据源
            this.$localData('set', 'operatingEnv', 'test');
            // this.getVisitList();
            console.log(this.$route.query.isEdit);
            if (this.$route.query.isEdit == 1) {
                this.getVisitList();
            } else {
                this.getLastVersionCode()
            }
            this.historyTitle = JSON.parse(localStorage.getItem('historyTitle'))
            this.selector = SelectorDefine().Visit;
        },
        methods: {
            ...mapMutations(['setIsEdit', 'setDataList', 'setSendDialogFormVisible', 'setSendForm', 'setIsFineshed']),
            ...mapActions(['getResearchPublishVersions', 'onPublishVersion']),
            onEditVisit() {
                this.setIsEdit(0)
                localStorage.setItem('isEdit', 0)
                let obj1 = {
                    versionCode: JSON.parse(localStorage.getItem('versionCode')),
                    researchCode: this.$route.query.code
                }
                this.setSendForm({
                    publishEnv: 0
                })
                this.onPublishVersion({...obj1})

            },
            onBack() {
                if (this.isHistiry || this.$route.query.historyList) {
                    this.$router.replace('/')
                } else {
                    this.$router.go(-1)
                }
            },
            getLastVersionCode() {
                // let env=  localData('get', 'operatingEnv') === 'test'? 'test': 'prod'
                getLastVersionCode({
                    researchCode: this.$route.query.code
                }).then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.versionCode = res.data.versionCode
                        localStorage.setItem('versionCode', JSON.stringify(this.versionCode))
                        this.getVisitList();
                    }
                }).catch((error) => {
                    console.log(error);
                })
            },
            onChangeViewToHistory() {
                this.$router.push({
                    name: 'visitHistory',
                    query: {
                        ...this.$route.query,
                        historyList: 1
                    }
                })
            },
            onSubmitSend() {
                this.$refs['sendForm'].validate((valid) => {
                    if (valid) {
                        let obj = {
                            ...this.sendForm,
                            versionCode: JSON.parse(localStorage.getItem('versionCode')),
                            researchCode: this.$route.query.code
                        }

                        if (this.visitHistory)
                            obj.status = 2;
                        this.sendBtnLoading = true
                        publishVersion(obj).then((res) => {
                            let type, msg;
                            if (res.code == 200) {
                                this.setSendDialogFormVisible(false)
                                type = 'success'
                                msg = res.msg
                                this.setSendForm({
                                    publishEnv: 1,
                                    versionNum: '',
                                    versionDate: '',
                                    versionCode: '',
                                    remark: ''
                                })
                                localStorage.setItem('isEdit', 0)
                                localStorage.setItem('versionCode', JSON.stringify(res.data.versionCode))
                                this.setIsEdit(0)
                                this.getResearchPublishVersions({
                                    researchCode: this.$route.query.code
                                })
                                this.getLastVersionCode()
                            } else {
                                type = 'error'
                                msg = '发布失败'
                            }
                            this.$message({
                                type: type,
                                message: msg
                            })
                            this.sendBtnLoading = false
                        }).catch((error) => {
                            this.sendBtnLoading = false
                        })
                    } else {

                    }
                })

            },
            draggableLog(listData) {
                let data = [];
                listData.forEach((item, index) => {
                    let obj = {};
                    obj.registryEntityCode = item.registryEntityCode;
                    obj.code = item.code;
                    obj.displayOrder = index + 1;
                    data.push(obj);
                })
                const postData = {
                    list: data
                };
                sortForm(data).then(res => {
                    this.$message({
                        type: 'success',
                        showClose: true,
                        message: '顺序更新成功',
                        center: true
                    });
                });
            },

            getVisitList() {
                const postData = {
                    researchCode: this.$route.query.code,
                    versionCode: JSON.parse(localStorage.getItem('versionCode'))
                };
                this.setDataList([])
                getVisit(postData)
                    .then(res => {
                        console.log('获取访视列表', res);
                        this.setDataList(res.data.dataList)
                        this.dialogVisible = false;
                        this.dialogVisibleAdd = false;
                        loadingInstance.close();
                        this.loading = false;
                        this.setIsFineshed(true)
                    })
                    .catch(error => {
                        loadingInstance.close();
                        this.loading = false;
                        this.setIsFineshed(true)
                    });
            },
            //添加访视
            visitAdd() {
                this.visitTitle = '新建访视';
                // console.log(this.$regionOptions)
                this.visitForm = this.$options.data.call(this).visitForm;
                this.$refs.visitForm && this.$refs.visitForm.clearValidate();
                this.dialogVisible = true;
            },
            addVisitConfirm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.submitButtonDisabled = true;
                        const postData = {
                            registryEntityCode: this.$route.query.code,
                            parentCode: 'Root',
                            displayNameCn: this.visitForm.title,
                            name: this.visitForm.title,
                            code: this.visitForm.id,
                            type: this.visitForm.type,
                            selectionType: this.visitForm.selectionType,
                            stage: this.visitForm.stage,
                            versionCode: JSON.parse(localStorage.getItem('versionCode'))
                        };
                        addVisit(postData)
                            .then(res => {
                                this.getVisitList();
                                this.$message({
                                    type: 'success',
                                    showClose: true,
                                    message: res.msg,
                                    center: true
                                });
                                this.setIsEdit(0)
                                localStorage.setItem('isEdit', 0)
                            })
                            .catch(error => {
                                this.submitButtonDisabled = false;
                            });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //编辑访视
            visitEdit(item) {
                this.editId = item.code;
                this.visitForm = {
                    title: item.displayNameCn,
                    id: item.code.split('_')[0],
                    type: item.type,
                    pkId: item.pkId || '',
                    selectionType: item.selectionType,
                    stage: item.stage || ''
                }
                this.visitTitle = '编辑访视';
                this.dialogVisible = true;
            },
            editVisitConfirm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.submitButtonDisabled = true;
                        const postData = {
                            pkId: this.visitForm.pkId,
                            registryEntityCode: this.$route.query.code,
                            parentCode: 'Root',
                            displayNameCn: this.visitForm.title,
                            name: this.visitForm.title,
                            code: this.editId,
                            type: this.visitForm.type,
                            selectionType: this.visitForm.selectionType,
                            versionCode: JSON.parse(localStorage.getItem('versionCode')),
                            stage: this.visitForm.stage
                        };
                        editVisit(postData)
                            .then(res => {
                                this.getVisitList();
                                this.$message({
                                    type: 'success',
                                    showClose: true,
                                    message: res.msg,
                                    center: true
                                });
                            })
                            .catch(error => {
                                this.submitButtonDisabled = false;
                            });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //删除访视
            visitDelete(item) {
                console.log(item);
                const postData = {
                    // pkId: item.pkId
                    registryEntityCode: item.registryEntityCode,
                    sectionCode: item.code
                };
                deleteVisit(postData)
                    .then(res => {
                        this.$message({
                            type: 'success',
                            showClose: true,
                            message: res.data,
                            center: true
                        });
                        this.getVisitList();
                    })
                    .catch(error => {

        });
    },
    //添加表单
    formAdd(visitItem) {
      this.visitTitle = '添加表单';
      this.visitItem = visitItem;
      this.visitFormAdd = this.$options.data.call(this).visitFormAdd;
      this.dialogVisibleAdd = true;
      this.$refs.visitFormAdd && this.$refs.visitFormAdd.clearValidate();
    },
    handleSaveForm(fnCode, formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitButtonDisabled = true;
          if (fnCode == 'edit') {
            editForm(this.editFormParams)
              .then(res => {
                this.getVisitList();
                this.$errInfo(res.data, 'success');
              })
              .catch(error => {
                this.submitButtonDisabled = false;
              });
          }

          if (fnCode == 'add') {
            addForm(this.addFormParams)
              .then(res => {
                this.getVisitList();
                this.$errInfo(res.data, 'success');
              }).catch(error => {
                this.submitButtonDisabled = false;
              });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    formEdit(item) {
      this.$refs.visitFormAdd && this.$refs.visitFormAdd.clearValidate();
      this.editFormId = item.code;
      this.visitFormAdd = {
        pkId: item.pkId,
        id: item.code.split('_')[0],
        parentCode: item.parentCode,
        displayNameCn: item.displayNameCn,
        name: item.name,
        selectionType: item.selectionType,
        isEpro: item.isEpro,
        category: item.category,
        allowAdd: item.allowAdd == 'true',
        repeatNum: item.repeatNum,
      };
      this.visitTitle = '编辑表单';
      this.dialogVisibleAdd = true;
    },
    //删除表单
    formDelete(item, formItem) {
      const postData = {
        registryEntityCode: this.$route.query.code,
        sectionCode: formItem.code,
      };
      deleteForm(postData).then(res => {
        this.getVisitList();
        this.$errInfo(res.data, 'success');
      })
    },
    //移动表单
    handleCommand(command) {
      const postData = {
        pkId: command.formItem.pkId,
        registryEntityCode: this.$route.query.code,
        code: command.formItem.code,
        parentCode: command.itemCli.code,
        displayNameCn: command.formItem.name,
        name: command.formItem.name,
        selectionType: command.formItem.selectionType
      };
      moveForm(postData)
        .then(res => {
          this.$errInfo('数据移动成功', 'success');
          this.getVisitList();
        })
        .catch(error => {

        });
    },
    //复制表单到
    handleCommandCopy(command) {
      const postData = {
        registryEntityCode: this.$route.query.code,
        code: command.formItem.code,
        parentCode: command.itemCli.code,
        displayNameCn: command.formItem.displayNameCn,
        name: command.formItem.name,
        selectionType: command.formItem.selectionType
      };
      copyForm(postData).then(res => {
        this.getVisitList();
      })

    },
    composeValue(item, itemCli, formItem) {
      return {
        'item': item,
        'itemCli': itemCli,
        'formItem': formItem
      }
    },
    //移动表单搜索
    tableCellCom(data, exclude) {
      return data.filter(item => item.displayNameCn.indexOf(this.searchString) != -1 && item.displayNameCn !== exclude.displayNameCn);
    },

    toFormEdit(item) {
      localStorage.removeItem('fullPath')
      this.$router.push({
        name: 'formDesigin',
        query: {
          sectionCode: item.code,
          registryEntityCode: this.$route.query.code,
          name: item.displayNameCn,
          selectionType: item.selectionType,
          parentCode: item.parentCode,
          patientId: item.patientId,
          repeatNum: item.repeatNum,
          category: item.category,
          isEpro: item.isEpro,
        }
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    // to.meta.keepAlive = true
    loadingInstance && loadingInstance.close();
    next()
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #606266;
}

/deep/ .el-dropdown-menu__item {
  line-height: 30px;
}

#visit {
  height: 100%;
  display: flex;
  flex-direction: column;

  // background: rgba(73, 150, 255, 0.14);
  .common-header {
    background: #e9ecef;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;

    .left-wrap {
      width: 180px;
      cursor: pointer;
    }

    .right-wrap {
      display: flex;
      align-items: center;

      span {
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .common-btn {
    padding: 0 16px;
    box-sizing: border-box;
    margin-top: 12px;
  }

  .visit-title {
    display: inline-block;
    max-width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
  }

  .visit-addOrRelease {
    padding: 0 16px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #FFF;
    background: #2c5c89;
    border-radius: 2px;
    margin: 13px 10px;
    border: none;
  }

  .visit-content {
    flex: 1;
    height: 100%;
    top: 130px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    overflow-x: auto;
    white-space: nowrap;

    .no-visit {
      width: 100%;
      height: 100%;
      background: #fff;

      .no-visit_center {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      .no-visit_des {
        width: 220px;
        height: 24px;
        font-size: 24px;
        color: #96B0C3;
        line-height: 24px;
        margin: 16px auto 0;
      }
    }

    .has-visit {
      height: calc(100% - 20px);
      padding: 10px;
      background: #fff;
      display: inline-block;

      .visit-item {
        display: inline-block;
        height: 100%;
        width: 320px;
        border: 1px solid #e1e1e1;
        margin-right: 5px;
        border-radius: 5px;

        .visit-item_header {
          height: 56px;
          line-height: 56px;
          background: #EAECEF;
          color: #212121;
          padding-left: 10px;
          box-sizing: border-box;
          border-radius: 5px 5px 0px 0px;

          span {
            display: inline-block;
            max-width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 16px;
            color: #000;
          }

          i {
            font-size: 21px;
            margin: -1px 4px;
            color: #2c5c89;

            &:nth-of-type(2),
            &:nth-of-type(3) {
              color: #2c5c89;
            }
          }
        }

        .visit-item_content {
          height: calc(100% - 106px);
          width: 100%;
          overflow-y: auto;
          margin-bottom: 10px;

          .visit-item_item {
            width: 300px;
            height: 46px;
            line-height: 46px;
            background: #EAECEF;
            margin: 5px auto;

            // text-align: center;
            span {
              display: inline-block;
              max-width: 150px;
              font-size: 16px;
              color: #000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            i {
              font-size: 20px;
              margin-right: 8px;
              color: #2c5c89;
            }
          }
        }

        .visit-item_footer {
          width: 300px;
          height: 32px;
          line-height: 32px;
          margin: 0 auto;
          background: #2c5c89;
          color: #FFF;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .has-visit_add {
      position: relative;
      display: inline-block;
      width: 70px;
      height: 100%;
      background: #fff;
      vertical-align: top;
      margin-left: 10px;
      text-align: center;

      .has-visit_addIcon {
        width: 28px;
        height: 28px;
        font-size: 24px;
        line-height: 24px;
        background: #2c5c89;
        color: #FFF;
        border-radius: 50%;
        border: 1px solid #2c5c89;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }

  }
}
</style>
<style lang="scss">
.el-dialog.visit-dialog {
  min-width: 256px;
}

.visit-cell_search {
  padding: 0 15px 8px;
  width: auto;
  border-bottom: 1px solid #e4e4e4;

  .el-input__suffix {
    right: 20px;
  }
}
</style>
